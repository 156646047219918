<template>
    <div>
        <div class="container fl-x-cc">
            <card border-radius="0" width="35r">
                <b-form :save-url="addUrl" @failure="onFailure" @success="formSuccess('A')" v-slot="{model, loading}">
                    <row>
                        <col-12 class="bb-1">
                            <text-el px="3" my="0" mb="2" fs="2" class="font-proxima-bold" font-weight="600">CHANGE PASSWORD</text-el>
                        </col-12>
                        <col-12 mt="3">
                            <div class="px-3">
                                <validated-input type="password" label="Old Password <span class='text-danger'>*</span> :"
                                                 v-model="model.old_password" :rules="rules.old_password"
                                                 :disabled="loading"></validated-input>
                            </div>
                        </col-12>
                        <col-12>
                            <div class="px-3">
                                <validated-input type="password" label="New Password <span class='text-danger'>*</span> :"
                                                 v-model="model.new_password" :rules="rules.new_password" :disabled="loading"></validated-input>
                            </div>
                        </col-12>
                        <col-12>
                            <div class="px-3">
                                <validated-input type="password" label="Retype Password <span class='text-danger'>*</span> :"
                                                 v-model="model.confirm_password" :rules="rules.confirm_password"
                                                 :disabled="loading"></validated-input>
                            </div>
                            <div class="text-danger mt-2 px-3 mb-2" v-if="model.new_password && model.confirm_password && model.new_password !== model.confirm_password">Please make
                                sure your passwords match.
                            </div>
                        </col-12>
                        <col-12>
                            <div class="px-3 pb-2">
                                <btn text="Change Password" loading-text="Saving..." size="sm" :disabled="loading"
                                     :loading="loading"/>
                            </div>
                        </col-12>
                    </row>
                </b-form>
                <div class="fl-x-c mt-4" v-if="updateSuccess">
                    <i class="text-success fa fa-check-circle fa-2x p-2 br-1"></i>
                    <span>Your new password has been updated.<br/>Please <a href="/login/">login</a> to continue.</span>
                </div>
            </card>
        </div>
    </div>
</template>

<script>
import urls from '../../data/urls';

export default {
    name: 'ChangePassword',
    data () {
        return {
            addUrl: urls.auth.changePassword,
            updateSuccess: false,
            rules: {
                old_password: {
                    required: true
                },
                new_password: {
                    required: true
                },
                confirm_password: {
                    required: true
                }
            }
        };
    },
    methods: {
        async formSuccess (action) {
            this.updateSuccess = true;
            // await this.$notify('Password changed Successfully', 'Success', {
            //     type: 'success'
            // });
            await this.$router.push('/login/');
        },
        onFailure (response) {
            const errors = response.data.errors;
            for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                    // const errorMessage = `${key}:  ${errors[key]}`;
                    const errorMessage = `${errors[key]}`;
                    this.$notify(errorMessage, 'Warning', { type: 'warning' });
                }
            }
        }
    }
};
</script>

<style scoped>

</style>
